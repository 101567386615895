<template>
  <div class="headers">
    <div class="message" v-if="message.length > 0">
      <img src="../assets/img/warning.png" />
      <el-carousel height="44px" direction="vertical" indicator-position="none" :interval="3000" autoplay>
        <el-carousel-item v-for="(item, index) in message" :key="index">
          <el-tooltip effect="dark" :disabled="item.showTooltip" placement="bottom">
            <span v-if="item.expirationDate <= 0" slot="content">商品有效期告警：{{ item.productName }}剩余有效期0天</span>
            <span v-else slot="content">商品有效期告警：{{ item.productName }}剩余有效期{{ item.expirationDate }}天</span>
            <div ref="productName" class="name" @click="goWarning">商品有效期告警：{{ item.productName }}剩余有效期{{ item.expirationDate }}天</div>
          </el-tooltip>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="head_wrap">
      <div class="head">
        <el-badge :value="msg" :max="99" :hidden="msg == 0" class="head_iconbadge">
          <el-popover popper-class="popover_concat_str" placement="bottom" width="1080" trigger="hover">
            <div class="popover_container">
              <div class="top">
                <div class="left">
                  <img class="warning" src="../assets/img/warning.png" />
                  <span>收货异常通知</span>
                </div>
              </div>
              <el-table :data="tableData">
                <el-table-column prop="deliveryNum" label="发货单号"></el-table-column>
                <el-table-column prop="shopName" label="商超名称"></el-table-column>
                <el-table-column prop="productName" label="商品名称"></el-table-column>
                <el-table-column prop="purchaseCount" label="采购数量"></el-table-column>
                <el-table-column prop="deliveryCount" label="发货数量"></el-table-column>
                <el-table-column prop="receiveCount" label="收货数量"></el-table-column>
              </el-table>
              <div class="showall">
                <el-button type="primary" size="small" plain @click="showAllList">查看全部</el-button>
              </div>
            </div>
            <div slot="reference" class="badge_container">
              <i class="el-icon-bell"></i>
            </div>
          </el-popover>
        </el-badge>

        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <div class="user">
              <el-avatar :src="obj.headPic || avatar"></el-avatar>
              <div class="username">{{ obj.userName }}</div>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="info">个人信息</el-dropdown-item>
            <el-dropdown-item command="password">修改密码</el-dropdown-item>
            <el-dropdown-item command="exit">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      avatar: require("@/assets/img/avatar.png"),
      obj: {},
      message: [],
      tableData: [],
      msg: null,
    }
  },
  created () {
    this.obj = this.$store.state.userInfo
    this.getDeliveryProductList()
    this.getAbnormalList()
  },
  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },
  },
  watch: {
    userInfo (val) {
      if (val) {
        this.obj = val
      }
    },
  },
  methods: {
    handleCommand (command) {
      this.$emit("command", command)
    },
    // 保质期预警跳转
    goWarning () {
      localStorage.removeItem('lastDetailPath')
      this.$store.commit("setIsDetail", null);
      this.$store.commit("setSideMenuActive", "/products/validityWarning")
      this.$router.push("/products/validityWarning")
    },

    // 保质期预警列表
    getDeliveryProductList () {
      this.$axios
        .get(this.$api.getDeliveryProductList, {
          params: {
            page: 1,
            pageSize: 5,
          },
        })
        .then(res => {
          if (res.data.result.list.length > 0) {
            this.message = res.data.result.list.map(e => {
              return {
                ...e,
                showTooltip: e.productName.length < 15 ? true : false,
              }
            })
          }
        })
    },
    // 收货异常列表
    getAbnormalList () {
      this.$axios
        .get(this.$api.sendDeliveryProductList, {
          params: {
            warningStatus: 1, //状态：1- 预警 2-已处理
            page: 1,
            pageSize: 10,
          },
        })
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            this.tableData = result.list
            this.getAbnormalNumbers()
          }
        })
    },
    // 收货异常列表消息数量
    getAbnormalNumbers () {
      this.$axios
        .get(this.$api.delivery_product_list_num, {
          params: {
            warningStatus: 1, //状态：1- 预警 2-已处理
          },
        })
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            this.msg = result
          }
        })
    },
    // 查看
    showAllList () {
      localStorage.removeItem('lastDetailPath')
      this.$store.commit("setIsDetail", null);
      this.$store.commit("setSideMenuActive", "/orderTaking/deliveryAbnormal")
      this.$router.push("/orderTaking/deliveryAbnormal")
    },
  },
}
</script>

<style scoped lang="scss">
.headers {
  display: flex;
  justify-content: right;
  width: 100%;
  height: 44px !important;
  background-color: #ebfffb;

  .message {
    display: flex;
    align-items: center;
    width: 400px;
    height: 44px;

    .el-carousel {
      width: 100%;

      .el-carousel__item {
        display: flex;
        align-items: center;
        color: #eb2500;
        font-size: 15px;
        cursor: pointer;

        .name {
          display: -webkit-box;
          word-break: break-all;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    img {
      width: 20px;
      height: 18px;
      margin-right: 10px;
    }
  }

  .head_wrap {
    width: 280px;
    height: 44px;
    padding-right: 25px;
    background: url(../assets/img/user.png) no-repeat;
    background-size: cover;
    box-sizing: border-box;

    .head {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 44px;
      color: #fff;
      font-size: 16px;
      margin: 0 auto;

      .head_iconbadge {
        margin-right: 30px;
        cursor: pointer;

        .badge_container {
          display: inline-block;
        }

        .el-icon-bell {
          color: #fff;
          font-size: 22px;
          font-weight: bold;
        }
      }

      .user {
        display: flex;
        align-items: center;

        .el-avatar {
          width: 22px;
          height: 22px;
        }

        .username {
          width: 100px;
          color: #fff;
          font-size: 16px;
          margin-left: 10px;
          cursor: pointer;

          display: -webkit-box;
          word-break: break-all;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.popover_concat_str {
  .popover_container {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      font-size: 16px;
      color: #eb2500;

      .left {
        display: flex;
        align-items: center;

        .warning {
          display: block;
          width: 18px;
          height: 16px;
          margin-right: 6px;
        }
      }
    }

    .showall {
      text-align: right;
      margin-top: 20px;
    }
  }
}
</style>
